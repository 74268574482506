
import Vue from "vue";
import { companiesMapGetters } from "@/store/modules/companies";
export default Vue.extend({
  name: "company-territories-column",
  props: {
    row: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  methods: {
    getTerritory() {
      const formattedCompanies: any = [];
      this.companies.map((company: any) => {
        const findCompany = this.row.territoryInfo.companyTerritories.find(
          (territory: any) => territory.companyNumber == company.companyNumber
        );
        if (findCompany) {
          return formattedCompanies.push(
            `${company.companyName}(${findCompany.companyNumber}) - ${findCompany.terr}`
          );
        }
      });
      return formattedCompanies;
    }
  },
  computed: {
    ...companiesMapGetters(["companies"])
  }
});
