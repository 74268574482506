
import { companiesMapActions } from "@/store/modules/companies";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { tableColumnsListGbsHelp } from "./GbsHelpTable";
import {
  gbsHelpMapActions,
  gbsHelpMapGetters,
  gbsHelpMapState
} from "@/store/modules/gbshelp";
import Vue from "vue";
import { ITableWrapperColumns } from "@/types";

interface IComponentData {
  error: string;
  message: string;
  loadingText: string;
  loading: boolean;
  tableColumns: ITableWrapperColumns;
}

export default Vue.extend({
  name: "GbsHelpBulkActivityPreview",
  data: (): IComponentData => ({
    error: "",
    message: "",
    loadingText: "",
    loading: false,
    tableColumns: tableColumnsListGbsHelp
  }),
  components: { NoResultsFound },
  methods: {
    ...companiesMapActions(["getCompanies"]),
    ...gbsHelpMapActions(["getGbsHelps"]),
    async fetchPageData(queryObject: any): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Loading PPCs. Please wait...";
        this.$emit("scrollTop");
        if (!queryObject.__sort) {
          queryObject["__sort"] = "county";
        }
        if (!queryObject.__limit) {
          queryObject["__limit"] = 50;
        }
        queryObject.ids = null;

        await this.getGbsHelps({
          query: {
            ...queryObject,
            _id: this.ids
          }
        });

        await this.getCompanies({});
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async viewCallback(dataReceived: any): Promise<void> {
      const { _id } = dataReceived;
      this.$router.push(`/admin/ppc/${_id}`).catch(() => {});
    }
  },
  computed: {
    ...gbsHelpMapGetters(["gbsHelp", "getTotalRecords", "getGbsHelpLinks"]),
    ...gbsHelpMapState(["makingApiRequest"]),
    ids(): string[] {
      let ids = this.$route.query.ids as string | string[];
      if (!ids) return [];
      if (!Array.isArray(ids)) {
        ids = ids.split(",");
      }
      return ids ? ids : [];
    }
  }
});
