import CompanyTerritories from "./CompanyTerritories.vue";

export const tableColumnsListGbsHelp = {
  zipCode: {
    formatDataKey: "Zip Code",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    colWidth: 100
  },
  city: {
    formatDataKey: "City",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 7,
    colWidth: 200
  },
  county: {
    formatDataKey: "County",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 4,
    colWidth: 130
  },
  "territoryInfo.ppc": {
    formatDataKey: "PPC",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    colWidth: 70
  },
  "territoryInfo.companyTerritories": {
    formatDataKey: "Company Territories",
    formatDataValue: (arg: any) => arg,
    formatDataComponent: CompanyTerritories,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  place: {
    formatDataKey: "Place",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2,
    colWidth: 120
  },
  countyCode: {
    formatDataKey: "County Code",
    formatDataValue: (arg: string) => (arg ? arg.toString() : ""),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 5,
    colWidth: 130
  },
  cityCode: {
    formatDataKey: "City Code",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 6,
    colWidth: 120
  }
};
